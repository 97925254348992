import Header from '../components/Header';
import Footer from '../components/Footer.js';
import Partnerbackend from '../components/Partnerbackend.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Partners() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white  mb-4 hdcsx">Our Partners</h3>   
            </div>
        </div>

        <Partnerbackend />

        <Footer />
     
        </>
    )
}

