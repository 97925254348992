import { BrowserRouter, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import ScrollToTop from './components/ScrollToTop';

import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Whychoose from './pages/Whychoose';
import Education from './pages/Education';
import Healthcare from './pages/Healthcare';
import Tax from './pages/Tax';
import Matrimonial from './pages/Matrimonial';
import Pagenotfound from './pages/Pagenotfound';
import Agriculture from './pages/Agriculture';
import Research from './pages/Research';
import Oldage from './pages/Oldage';
import OrganTransplant from './pages/OrganTransplant';
import Housing from './pages/Housing';
import Business from './pages/Business';
import ImportExport from './pages/ImportExport';
import Hungerfree from './pages/Hungerfree';
import Covid from './pages/Covid';
import Handicap from './pages/Handicap';
import Cancer from './pages/Cancer';
import Team from './pages/Team';
import LegalServices from './pages/LegalServices';

import Partners from './pages/Partners';




function App() {
  return (
    <div>
      
      <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Whychoose" element={<Whychoose />} />
        <Route path="/Education" element={<Education />} />
        <Route path="/Healthcare" element={<Healthcare />} />
        <Route path="/Tax" element={<Tax />} />
        <Route path="/Matrimonial" element={<Matrimonial />} />
        <Route path="/Pagenotfound" element={<Pagenotfound />} />
        <Route path="/Agriculture" element={<Agriculture />} />
        <Route path="/Research" element={<Research />} />
        <Route path="/Oldage" element={<Oldage />} />
        <Route path="/OrganTransplant" element={<OrganTransplant />} />
        <Route path="/Housing" element={<Housing />} />
        <Route path="/Business" element={<Business />} />
        <Route path="/ImportExport" element={<ImportExport />} />
        <Route path="/Hungerfree" element={<Hungerfree />} />
        <Route path="/Covid" element={<Covid />} />
        <Route path="/Handicap" element={<Handicap />} />
        <Route path="/Cancer" element={<Cancer />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/legal" element={<LegalServices />} />
        <Route path="/partner" element={<Partners />} />
        
        
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;