import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocation, faPhone, faMailForward, faMarker, faCopyright, faArrowRight  } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import logo from '../components/img/logo2.png';

export default function Footer() {
  const currentYear = new Date().getFullYear();

    return (
        <>
        <div className="bg-dark pt-5 pb-5 mt-5">
            <div className="container">
            <div className="row ">
                <div className="col-md-6 col-12 col-xl-6 col-lg-6 col-sm-12">
                  {/* <h4 className="text-white">Vision</h4> */}
                  <img src={logo} className="logo mb-3" alt="Logo"></img>
                  <p className="text-white ft-13 mb-3">Welcome to the NRI Welfare Association (NRIWA), a
                   distinguished organization committed to serving the unique needs and challenges faced 
                   by NRI Welfare Association across various domains. At NRIWA, our mission is to provide 
                   comprehensive consultations, support, and solutions to address the concerns of and 
                   facilitate a seamless experience in India.</p>
                
                </div>
                
                {/* <div className="col-md-3 col-12 col-xl-3 col-lg-3 col-sm-12">
                  <h4 className="text-white">Quick Links</h4>
                  <ul className="list-unstyled">
                    <li><Link to="/" className="text-white fta ft-13" aria-current="page"><FontAwesomeIcon icon={faMarker} />&nbsp;Home</Link></li>
                    <li><Link to="/about" className="text-white fta ft-13"><FontAwesomeIcon icon={faMarker} />&nbsp;About</Link></li>
                    <li><Link to="/contact" className="text-white fta ft-13"><FontAwesomeIcon icon={faMarker} />&nbsp;Contact</Link></li>
                  </ul>
                </div> */}

                <div className="col-md-3 col-12 col-xl-3 col-lg-3 col-sm-12">
                <h4 className="text-white">Our Services</h4>
                <ul className="list-unstyled">
                    <li><Link to="/Education" className="text-white fta ft-13" aria-current="page"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Education & Job Placement</Link></li>
                    <li><Link to="/Healthcare" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Healthcare</Link></li>
                    <li><Link to="/Tax" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Taxation & Consultation</Link></li>
                    <li><Link to="/Matrimonial" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Matrimonial</Link></li>
                    <li><Link to="/Agriculture" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Agriculture & Farming</Link></li>
                    <li><Link to="/Research" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Research & Development  </Link></li>
                    <li><Link to="/Oldage" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Oldage Homes</Link></li>
                    <li><Link to="/OrganTransplant" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Organ Transplant</Link></li>
                    <li><Link to="/Housing" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Housing & Real E-State</Link></li>
                    <li><Link to="/Business" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Business Setup & Collaboration</Link></li>
                    <li><Link to="/ImportExport" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Import And Export</Link></li>
                    <li><Link to="/Hungerfree" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Hunger Free Nagpur </Link></li>
                    <li><Link to="/Covid" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Covid Orphans</Link></li>
                    <li><Link to="/Handicap" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Handicap Relef</Link></li>
                    <li><Link to="/Cancer" className="text-white fta ft-13"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Cancer Treatment</Link></li>

                  </ul>
                </div>

                <div className="col-md-3 col-12 col-xl-3 col-lg-3 col-sm-12">
                <h4 className="text-white">Follow Us</h4>
                  {/* <div className="text-white ft-13"><FontAwesomeIcon icon={faMapLocation} /> &nbsp;
                  XYZ adresss, Nagpur, Maharashtra, India </div> */}

                  {/* <div className="text-white ft-13"><FontAwesomeIcon icon={faPhone} /> &nbsp;
                  +91 9999999999</div> */}

                  <div className="text-white ft-13"><FontAwesomeIcon icon={faMailForward} /> &nbsp;
                  arshad@nriwa.com</div>
                </div>


            </div>
            </div>
            
        </div>
        

        <div className="pcol2 topb">
            <div className="container">
            <div className="row py-2">
                <div className="col-md-12 text-white copy text-center ft-13">Copyright <FontAwesomeIcon icon={faCopyright} />&nbsp; 
                {currentYear} All Rights Reserved By NRIWA.COM. Designed By IGN SOFTWARES.
                </div>
                <div className="col-md-6">
                  
                </div>
            </div>
            </div>
            
        </div>

        </>
    )
}