import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Call your backend API here to submit the form data
        fetch('https://nriwa.com/app/submit.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Specify the content type as JSON
            },
            body: JSON.stringify(formData)
        })
        .then(response => response.json())
        .then(data => {
            console.log("Response from API:", data); // Log the response data to the console
            // Clear form data
            setFormData({
                firstname: '',
                lastname: '',
                email: '',
                message: ''
            });
            // Show success toast message
            toast.success("Form submitted successfully!");
        })
        .catch(error => {
            console.error('Error:', error);
            // Show error toast message
            toast.error("An error occurred while submitting the form.");
        });
    };
    

    return (

        <>
        <Header />
        <ToastContainer />
        <section className="nbghpp">
        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Contact Us</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <div className="col-md-4 col-12">
                    <h4 className="h33">Message Us</h4>
                    <p className="text-muted"><i>If you have any questions, inquiries, or feedback, please don't hesitate to reach out to us. Our dedicated team is here to assist you with any assistance you may need.</i></p>
                </div>
                <div className="col-md-8 col-12  p-4 bg-white lokbgsd">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="firstName" className="form-label lbl">First Name</label>
                                <input type="text" className="form-control" id="firstName" name="firstname" value={formData.firstname} onChange={handleChange} aria-label="First name" />
                            </div>
                            <div className="col">
                                <label htmlFor="lastName" className="form-label lbl">Last Name</label>
                                <input type="text" className="form-control" id="lastName" name="lastname" value={formData.lastname} onChange={handleChange} aria-label="Last name" />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label lbl">Email</label>
                            <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter Email" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="message" className="form-label lbl">Message</label>
                            <textarea name="message" className="form-control" id="message" value={formData.message} onChange={handleChange} rows="5" placeholder="Text Here..."></textarea>
                        </div>
                        <div className="text-end">
                            <button type="submit" className="btn btn-warning lbl">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <Footer />
        </section>
        </>
    );
}
